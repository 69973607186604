#root {
  height: 100vh;
  overflow: auto;
}
.button.login {
    background-image: linear-gradient(90deg,#21a954,#25d366,#127a39);
    background-size: 200%;
    border: none !important;
    border-radius: 25px !important;
    color: #fff !important;
    cursor: pointer !important;
    outline: none !important;
    transition: .5s !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%) !important;
}
.button {
    background-image: linear-gradient(90deg,transparent,#3a3a3a1f,transparent);
    background-size: 200%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    font-family: Poppins,sans-serif;
    font-size: 1.2rem;
    height: 50px;
    margin: 1rem 0;
    outline: none;
    text-transform: uppercase;
    transition: .5s;
    width: 100%;
    box-shadow: 0px 3px 5px -2px rgb(0 0 0 / 20%), 0px 2px 5px 0px rgb(0 0 0 / 14%) !important;
}
.MuiButton-root {
    background-image: linear-gradient(90deg,transparent,#3a3a3a1f,transparent);
    background-size: 200%;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    outline: none !important;
    transition: .5s !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%) !important;
}
.button:hover,.MuiButton-root:hover{background-position:100%}

.listmenu {
    overflow-y: hidden;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    padding-bottom: .75rem;
}

@media (min-width: 600px) {
    .item {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(34, 41, 47, 0);
        direction: ltr;
        font-weight: 400;
        list-style: none;
        box-sizing: border-box;
        position: relative;
        white-space: nowrap;
    }

    .item.active,
    .item.sub.active{
        color: #d0d1d0 !important;
        margin: 0 15px;
        font-weight: 400;
        box-shadow: 0 0 10px 1px rgb(0 64 5 / 37%);
        border-radius: 4px;
        background: linear-gradient(90deg, #3c8d5a, #3b7e54);
    }

    .item>a>div {
        min-width: 40px;
    }

    .item.active>li>a {
        margin: 0;
    }

    .item>a,
    .item.sub>div {
        transition: transform 500ms;
    }

    .item>a:hover,
    .item.sub>div:hover {
        transform: translateX(15px);
        background-color: transparent;
    }

    .item.active>a:hover,
    .item.sub.active>div:hover {
        transform: none;
        background-color: transparent;
        transition: none;
    }

    .item.active>a div span {
        color: #fff;
    }

    .item.active>a>div>svg,
    .item.active>div>div>svg {
        vertical-align: middle;
        transition: transform .25s ease;
        position: relative;
        top: 1px;
        color: #fff;
        flex-shrink: 0;
        margin-right: 0.5rem;
    }
    .item>a>div>svg {
        vertical-align: middle;
        transition: transform .25s ease;
        position: relative;
        top: 1px;
        color: inherit;
        flex-shrink: 0;
        margin-right: 0.5rem;
    }

    .item>.active>a>div>svg {
        color: #fff;
    }
    .subitem.active,
    .subitem.active>div>svg {
        color: #30a35a
    }
}